const getByPath = (obj, path) =>
  path
    .split(".")
    .reduce((accumulator, currentValue) => accumulator[currentValue], obj)

function createVarFromObject(obj, path, prefix = "") {
  let defs = getByPath(obj, path)
  if (prefix.length) {
    prefix += "-"
  }
  // if not is array, simply return value
  if (typeof defs !== "object")
    return `--${prefix.length ? prefix : path.replace(".", "-")}: ${defs};`

  defs = Object.entries(defs)
  // if is array of arrays, use 0 as key and 1 as value
  if (Array.isArray(defs[0]))
    return defs.map(([key, value]) => `--${prefix}${key}: ${value};`).join("\n")
  // if is array of values
  return defs
    .map((value, index) => `--${prefix}-${index}: ${value};`)
    .join("\n")
}

// abstract tokens
const abstracts = {
  blue: {
    400: "#2376FF",
  },
  red: {
    200: "#f7e9e9",
    400: "#ff4341",
  },
}

const breakpoints = [0, 360, 640, 768, 1024, 1280, 1440, 1920]
const breakpointAlias = ["sm", "md", "lg", "xl", "xxl", "x3l", "x4l", "x5l"]
const breakpointByAlias = breakpoints.reduce(
  (accu, value, index) => ({
    ...accu,
    [breakpointAlias[index]]: value,
  }),
  {}
)

// helpers
const tokens = {
  color: {
    text: "#020101",
    background: "#ffffff",
    backgroundSecondary: abstracts.red[200],
    primary: abstracts.blue[400],
    secondary: abstracts.red[400],
  },
  space: ["0px", "8px", "16px", "32px", "40px"],
  font: {
    body: `"Body"`,
    headline: `"Headline"`,
  },
  radii: ["10px"],
}
const t = tokens

const patterns = {
  inlineBlocks: {
    display: "inline-block",
    margin: "0 1em 1em 0",
    "&:last-of-type": {
      marginRight: 0,
    },
  },
}

const styles = {
  global: {
    body: {
      fontFamily:
        "var(--font-body), -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji",
    },
  },
  text: {
    default: [
      {
        fontSize: "15px",
        lineHeight: "24px",
        letterSpacing: "0.021875em",
      },
      {
        fontSize: "20px",
        lineHeight: "28px",
        letterSpacing: "0.022083em",
        "@media (min-width: 640px)": {
          fontSize: "24px",
          lineHeight: "36px",
        },
      },
      {
        fontSize: "18px",
        lineHeight: "28px",
        letterSpacing: "0.022222em",
        "@media (min-width: 640px)": {
          fontSize: "22px",
          lineHeight: "32px",
        },
      },
    ],
    headline: [
      null,
      {
        fontFamily: "var(--font-headline)",
        fontSize: "46px",
        lineHeight: "58px",
        fontWeight: 400,
        letterSpacing: "-0.011148em",
        fontFeatureSettings: `"liga"`,
        "@media (min-width: 640px)": {
          fontSize: "72px",
          lineHeight: "80px",
        },
        "@media (min-width: 1024px)": {
          fontSize: "90px",
          lineHeight: "100px",
        },
      },
      {
        fontFamily: "var(--font-headline)",
        fontWeight: 400,
        fontSize: "26px",
        lineHeight: "36px",
        letterSpacing: "0.022083em",
        fontFeatureSettings: `"liga"`,
        "@media (min-width: 640px)": {
          fontSize: "36px",
          lineHeight: "48px",
        },
        "@media (min-width: 1024px)": {
          fontSize: "48px",
          lineHeight: "56px",
        },
      },
      {
        fontFamily: "var(--font-headline)",
        fontWeight: 400,
        fontSize: "22px",
        lineHeight: "32px",
        letterSpacing: "0.022083em",
        fontFeatureSettings: `"liga"`,
        "@media (min-width: 640px)": {
          fontSize: "30px",
          lineHeight: "36px",
        },
      },
    ],
  },
  button: {
    // sizes: {
    //   _map: {
    //     sm: 300,
    //     md: 400,
    //     lg: 700,
    //   },
    //   300: { fontSize: "20px", lineHeight: "32px", padding: "16px 56px" },
    //   400: { fontSize: "20px", lineHeight: "32px", padding: "16px 56px" },
    //   700: { fontSize: "20px", lineHeight: "32px", padding: "16px 56px" },
    // },
    // _shared: {
    //  // some styles
    //  size: {
    //    small: {}
    //    normal: {}
    //    big: {}
    // }
    // }
    default: {
      ...patterns.inlineBlocks,
      cursor: "pointer",
      color: t.color.background,
      backgroundColor: t.color.primary,
      border: 0,
      borderRadius: t.radii[0],
      fontSize: "21px",
      fontWeight: 700,
      lineHeight: "32px",
      letterSpacing: "0.022381em",
      textDecoration: "none",
      padding: "16px 56px",
      "&:hover": {
        backgroundColor: "#1253bf",
      },
    },
    secondary: {
      ...patterns.inlineBlocks,
      cursor: "pointer",
      border: "rgba(94,94,94,0.5) 1px solid",
      background: "none",
      borderRadius: t.radii[0],
      color: "inherit",
      fontSize: "16px",
      fontWeight: 300,
      lineHeight: "28px",
      letterSpacing: "0.022381em",
      textDecoration: "none",
      padding: "18px 48px",
      "&:hover": {
        borderColor: "#1253bf",
        backgroundColor: "#e6ecf5",
      },
    },
  },
  grid: {
    default: {
      gutter: 60,
    },
  },
}

const mediaquery = (
  breakpointAlias,
  styles
) => `@media (min-width: ${breakpointByAlias[breakpointAlias]}px) {
${styles}
}`

const defaultTheme = {
  breakpoints,
  breakpointAlias,
  tokens,
  styles,
  helpers: {
    breakpointByAlias,
    mediaquery,
  },
}

defaultTheme.getColor = function getColor(name, weight) {
  const color = this.tokens.color[name]
  return weight ? color[weight] : color
}
defaultTheme.getSpace = function getSpace(size) {
  return this.tokens.space[size]
}

// console.log(defaultTheme, defaultTheme.getSpace(1))
/*
abstracts
tokens
patterns (= tokens in use)

//TODO: improve theming


const defaultTheme = {
  tokens: {
    color: {
      text: "#020101",
      background: "#ffffff",
      blue: {
        400: "#d51635",
      }
    },
    space: {
      layout: ["1em"],
    },
  },
  tokensApplied: {
    color: {
      primary
    }
  }
}
*/
export default defaultTheme
