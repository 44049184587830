/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import { Global, css } from "@emotion/core"
import { ThemeProvider, useTheme } from "emotion-theming"
import theme from "../theme/theme"
import Header from "./header"

const getByPath = (obj, path) =>
  path
    .split(".")
    .reduce((accumulator, currentValue) => accumulator[currentValue], obj)

function createVarsFromObject(obj, path, prefix = "") {
  let defs = getByPath(obj, path)
  if (prefix.length) {
    prefix += "-"
  }
  // if not is array, simply return value
  if (typeof defs !== "object")
    return `--${prefix.length ? prefix : path.replace(".", "-")}: ${defs};`

  defs = Object.entries(defs)
  // if is array of arrays, use 0 as key and 1 as value
  if (Array.isArray(defs[0]))
    return defs.map(([key, value]) => `--${prefix}${key}: ${value};`).join("\n")
  // if is array of values
  return defs
    .map((value, index) => `--${prefix}-${index}: ${value};`)
    .join("\n")
}

const GlobalStyles = () => {
  const theme = useTheme()
  return (
    <Global
      styles={css(
        css`
          @font-face {
            font-family: "Body";
            font-style: normal;
            font-weight: 400;
            font-display: swap;
            src: url("/fonts/inter/Inter-Light.woff2?v=3.15") format("woff2"),
              url("/fonts/inter/Inter-Light.woff?v=3.15") format("woff");
          }
          @font-face {
            font-family: "Body";
            font-style: normal;
            font-weight: 700;
            font-display: swap;
            src: url("/fonts/inter/Inter-Regular.woff2?v=3.15") format("woff2"),
              url("/fonts/inter/Inter-Regular.woff?v=3.15") format("woff");
          }
          @font-face {
            font-family: "Headline";
            font-style: normal;
            font-weight: 400;
            font-display: swap;
            src: url("/fonts/young-serif/YoungSerif-Regular.woff2")
                format("woff2"),
              url("/fonts/young-serif/YoungSerif-Regular.woff") format("woff");
          }

          h1,
          h2,
          h3,
          h4,
          h5,
          h6,
          p {
            font-weight: inherit;
            margin: 0;
            padding: 0;
          }

          a,
          button {
            font-family: inherit;
            &:focus {
              outline: 0;
              box-shadow: 0 0 0 2pt var(--color-primary);
            }
          }

          body {
            // background: #f7e9e9;
            margin: 0;
            padding: 0;
            overflow-x: hidden;
          }

          input,
          textarea {
            font-family: var(--font-body);
            font-size: inherit;
            line-height: inherit;
          }

          #sntch_button {
            right: 20px !important;
            bottom: 20px !important;
            box-shadow: rgb(36 118 254) 0px 0 0px 4px,
              rgb(0 0 0 / 40%) 0px 3px 13px, rgba(0, 0, 0, 0.23) 0px 3px 6px 3px !important;
          }
        `,
        theme.styles.global,
        css`
          :root {
            ${createVarsFromObject(theme, "tokens.color", "color")}
            ${createVarsFromObject(
              theme,
              "tokens.space",
              "space"
            )}
    ${createVarsFromObject(theme, "tokens.font", "font")}
          }
        `
      )}
    />
  )
}

const Layout = ({ children, backToHome }) => {
  useEffect(() => {
    if (
      typeof window !== "undefined" &&
      window.sntchChat &&
      window.sntchChat.Init
    )
      window.sntchChat.Init(141564)
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Header backToHome={backToHome} />
      <main>{children}</main>
      <footer>
        Sashimi Web ist ein Service von{" "}
        <a href="https://fucking.digital">fucking.digital</a>
      </footer>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
