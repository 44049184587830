import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
/** @jsx jsx */
import { jsx, css } from "@emotion/core"
import styled from "@emotion/styled"
import { useTheme } from "emotion-theming"

const Wrapper = styled.div`
  margin: 0 auto;
  width: 90%;
  max-width: 560px;
  ${props => props.theme.helpers.mediaquery("lg", "width: 77.5%;")}
  ${props => props.theme.helpers.mediaquery("xl", "max-width: 1240px;")}
`

const Button = styled.button(
  props => props.theme.styles.button[props.variant || "default"]
)

const Header = ({ backToHome }) => {
  return (
    <header
      css={css`
        position: fixed;
        z-index: 99999;
        width: 100%;
        background: white;
        border-bottom: solid 1px rgba(0, 0, 0, 0.2);
      `}
    >
      <Wrapper
        css={css`
          padding: 1em 0;
          display: flex;
          align-items: center;
          justify-content: space-between;
        `}
      >
        {backToHome ? (
          <Link
            to="/"
            css={css`
              display: inline-block;
              text-decoration: none;
            `}
          >
            ← Zurück
          </Link>
        ) : (
          <>
            <Link
              to="/"
              css={css`
                display: inline-block;
                color: #fff;
                text-decoration: none;
              `}
            >
              <img
                src="/images/sashimiweb-logo.svg"
                alt="Sashimi Web"
                css={css`
                  display: block;
                  width: 270px;
                `}
              />
            </Link>

            <Button
              as={Link}
              to="/bestellen/"
              css={css`
                font-size: 14px;
                line-height: 20px;
                padding: 7px 16px;
                border-radius: 5px;
                margin-bottom: 0;
              `}
            >
              Website bestellen
            </Button>
          </>
        )}
      </Wrapper>
    </header>
  )
}

export default Header
